<template>
  <div class="shop-settings">
    <div class="page-header">
      <h1>{{ $t('Settings') }} > {{ $t('SNS Settings') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('SNS Settings') }}</h2>
      </div>
      <div class="form-content">
        <el-form ref="facebook-setting-form" class="facebook-setting-form" :model="form" label-width="200px">
          <div class="row">
            <el-form-item label="Facebook Access Token" class="facebook-access-token">
              <el-input v-model="form.facebookAccessToken"></el-input>
            </el-form-item>
            <button class="btn btn-primary" @click.prevent="loadFacebookPages"> {{ $t('Load Facebok Pages List') }}</button>
          </div>
          <div class="row">
            <el-form-item class="facebook-page-row" :label="$t('Facebook Pages')" v-if="facebookPages.length !== 0">
              <el-select v-model="form.facebookPage" :placeholder="$t('Please select page')">
                <el-option
                  v-for="item in facebookPages"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <div class="save-button-wrapper">
          <button class="btn btn-primary" @click="updateSettings">{{ $t('Save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Sns from '@/lib/sns';

export default {
  name: 'SnsSetting',
  data(){
    return {
      disabled: true,
      showTokenField: false,
      facebookPages: [],
      form: {
        facebookUserId: '',
        facebookAccessToken: '',
        facebookPage: '',
      },
    }
  },
  mounted(){
    this.loadSNSSettings();
  },
  methods:{
    startEdit(){
      this.disabled = false;
    },
    initTokenField(){
      console.log()
      this.showTokenField = true;
      this.form.facebookAccessToken = '';
    },
    async loadFacebookPages(){
      const loginInfo = Common.getLoginInfo();
      const facebookPages = await Sns.loadFacebookPages(this.apiUrl, this.form.facebookAccessToken, loginInfo);
      this.facebookPages = facebookPages;
    },
    async loadSNSSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let facebookPage = await Common.loadConfig(this.apiUrl, 'facebook_page', loginInfo);
        facebookPage = facebookPage['config_value'];
        this.form.facebookPage = facebookPage;

        //If facebook page setting is existed
        if(facebookPage.length !== 0){
          const facebookPages = await Sns.loadFacebookPages(this.apiUrl, null, loginInfo);
          this.facebookPages = facebookPages;
        }
      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        const facebookPageName = this.form.facebookPage;
        await Common.updateConfig(this.apiUrl, 'facebook_page', facebookPageName, loginInfo);

        this.disabled = true;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.facebook-setting-form{
  .row{
    align-items: center;
    padding-left: 15px;
  }
  .facebook-access-token{
    display: flex;
    align-items: center;
  }
  .el-form-item__label{
    text-align: left;
  }
  .el-form-item__content{
    margin-left: 20px !important;
  }
  .facebook-page-row .el-form-item__content{
    margin-left: 0 !important;
  }
  .btn-primary{
    position: relative;
    bottom: 12px;
    margin-left: 30px;
  }
}

.save-button-wrapper{
  text-align: right;
}
</style>
