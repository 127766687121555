import queryString from 'query-string';

const Sns = {
  async loadFacebookPages(baseApiUrl, accessToken, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryFields = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };
      if(accessToken){
        queryFields['facebook_access_token'] = accessToken;
      }
      const query = queryString.stringify(queryFields);
      const apiUrl = `${baseApiUrl}/sns/pages?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading pages information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
  async loadFacebookPage(baseApiUrl, loginInfo){
    return new Promise(async(resolve, reject) => {
      const queryFields = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
      };
      const query = queryString.stringify(queryFields);
      const apiUrl = `${baseApiUrl}/sns/posts?${query}`;
      const fetchResult = await fetch(apiUrl);
      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot loading pages information. Maybe there are some network problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },

  async createFacebookPost(baseApiUrl, postContent, loginInfo){
    return new Promise(async(resolve, reject) => {
      const postValues = {
        userId: loginInfo['userId'],
        token: loginInfo['token'],
        postContent: postContent,
      };

      const apiUrl = `${baseApiUrl}/sns/post`;
      const fetchResult = await fetch(apiUrl, {
        method: 'POST',
        headers: {
         'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(postValues),
      });

      const status = await fetchResult.status;

      //If cannot connect with API server
      if(status !== 200 && status !== 401){
        const err = 'Cannot create facebook post information. Maybe there are some fields problem?';
        reject(err);
        return;
      }

      //If user do not login or no permission
      if(status === 401){
        const err = 'Sorry, You don\'t had permission to access it.';
        reject(err);
        return;
      }

      const resultJson = await fetchResult.json();

      resolve(resultJson);
    });
  },
};

export default Sns;
